.dropzone {
  border: var(--#{$prefix}border-width) dashed var(--#{$prefix}border-color);
  color: var(--#{$prefix}secondary);
  padding: 1rem;

  &.dz-drag-hover {
    border: var(--#{$prefix}border-width) dashed var(--#{$prefix}primary);
    background: rgba(var(--#{$prefix}primary-rgb), .01);
    color: var(--#{$prefix}primary);

    .dz-message {
      opacity: 1;
    }
  }

  .dz-preview {
    margin: .5rem;

    .dz-image {
      border-radius: var(--#{$prefix}border-radius);
    }

    .dz-success-mark {
      height: 54px;
    }
  }
}