.clr-picker {
  box-shadow: var(--#{$prefix}box-shadow-dropdown);
  background-color: var(--#{$prefix}bg-surface);
}

input.clr-color {
  border-radius: var(--#{$prefix}border-radius);
  color: var(--#{$prefix}body-color);
  border-color: var(--#{$prefix}border-color);
  background: transparent;

  &:focus {
    @include focus-ring(true);
  }
}

.clr-swatches {
  button {
    border-radius: var(--#{$prefix}border-radius);
    padding: 0 2px 4px 2px;

    &:focus {
      @include focus-ring;
    }
  }
}

.clr-preview {
  border-radius: var(--#{$prefix}border-radius);
  overflow: visible;

  button,
  &:before,
  &:after {
    border-radius: var(--#{$prefix}border-radius);
  }

  button {
    &:focus {
      @include focus-ring;
    }
  }
}

.clr-field {
  display: block;
  
  button {
    width: 1.5rem;
    height: 1.5rem;
    left: 6px;
    right: auto;
    border-radius: var(--#{$prefix}border-radius);

    &:after {
      box-shadow: inset 0 0 0 1px var(--#{$prefix}border-color-translucent);
    }

    &:focus {
      @include focus-ring;
    }
  }

  input {
    padding-left: 2.5rem;
  }
}
