:root {
  --gl-star-size: auto;
  --gl-star-color: var(--#{$prefix}yellow);
  --gl-star-color-inactive: var(--#{$prefix}border-color);
}

[data-star-rating] {
  svg {
    width: var(--#{$prefix}icon-size, --gl-star-size);
    height: var(--#{$prefix}icon-size, --gl-star-size);
  }

  :not(.gl-active) > .gl-star-full {
    color: var(--gl-star-color-inactive) !important;
  }

  .gl-active > .gl-star-full {
    color: var(--gl-star-color);
  }
}
