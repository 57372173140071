svg {
  -ms-touch-action: none;
      touch-action: none;
}

image, text, .jvm-zoomin, .jvm-zoomout {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.jvm-container {
  -ms-touch-action: none;
      touch-action: none;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.jvm-tooltip {
  border-radius: 3px;
  background-color: #5c5cff;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.2);
  padding: 3px 5px;
  white-space: nowrap;
  position: absolute;
  display: none;
  color: #FFF;
}

.jvm-tooltip.active {
  display: block;
}

.jvm-zoom-btn {
  border-radius: 3px;
  background-color: #292929;
  padding: 3px;
  box-sizing: border-box;
  position: absolute;
  line-height: 10px;
  cursor: pointer;
  color: #FFF;
  height: 15px;
  width: 15px;
  left: 10px;
}

.jvm-zoom-btn.jvm-zoomout {
  top: 30px;
}

.jvm-zoom-btn.jvm-zoomin {
  top: 10px;
}

.jvm-series-container {
  right: 15px;
  position: absolute;
}

.jvm-series-container.jvm-series-h {
  bottom: 15px;
}

.jvm-series-container.jvm-series-v {
  top: 15px;
}

.jvm-series-container .jvm-legend {
  background-color: #FFF;
  border: 1px solid #e5e7eb;
  margin-left: 0.75rem;
  border-radius: 0.25rem;
  border-color: #e5e7eb;
  padding: 0.6rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  float: left;
}

.jvm-series-container .jvm-legend .jvm-legend-title {
  line-height: 1;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 0.5rem;
  margin-bottom: 0.575rem;
  text-align: left;
}

.jvm-series-container .jvm-legend .jvm-legend-inner {
  overflow: hidden;
}

.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick {
  overflow: hidden;
  min-width: 40px;
}

.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick:not(:first-child) {
  margin-top: 0.575rem;
}

.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick .jvm-legend-tick-sample {
  border-radius: 4px;
  margin-right: .65rem;
  height: 16px;
  width: 16px;
  float: left;
}

.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick .jvm-legend-tick-text {
  font-size: 12px;
  text-align: center;
  float: left;
}

.jvm-line[animation="true"] {
  -webkit-animation: jvm-line-animation 10s linear forwards infinite;
  animation: jvm-line-animation 10s linear forwards infinite;
}

@-webkit-keyframes jvm-line-animation {
  from {
    stroke-dashoffset: 250;
  }
}

@keyframes jvm-line-animation {
  from {
    stroke-dashoffset: 250;
  }
}
