.btn-group,
.btn-group-vertical {
  box-shadow: $input-box-shadow;

  > .btn-check:checked + .btn,
  > .btn:active,
  > .btn.active {
    z-index: 5;
  }

  > .btn-check:focus + .btn,
  > .btn:hover,
  > .btn:focus {
    z-index: 1;
  }
}