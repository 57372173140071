.tox-tinymce {
  border: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color) !important;
  border-radius: $border-radius !important;
  font-family: var(--#{$prefix}font-sans-serif) !important;
}

.tox-toolbar__group {
  padding: 0 .5rem 0;
}

.tox .tox-toolbar__primary {
  background: transparent !important;
}

.tox:not(.tox-tinymce-inline) {
  .tox-editor-header {
    border-bottom: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color) !important;
    box-shadow: none !important;
    padding: 0 !important;
  }
}

.tox-tbtn {
  margin: 0 !important; 
}

.tox-statusbar {
  border-top: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color) !important;
}

.tox .tox-toolbar-overlord,
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  background: transparent !important;
}