.img-responsive {
  --#{$prefix}img-responsive-ratio: #{percentage(.75)};
  background: no-repeat center/cover;
  padding-top: var(--#{$prefix}img-responsive-ratio);
}

.img-responsive-grid {
  padding-top: calc(var(--#{$prefix}img-responsive-ratio) - calc(var(--#{$prefix}gutter-y) / 2));
}

@each $key, $ratio in $aspect-ratios {
  .img-responsive-#{$key} {
    --#{$prefix}img-responsive-ratio: #{$ratio};
  }
}
