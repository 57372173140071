.tag {
  --#{$prefix}tag-height: 1.5rem;
  border: $border-width solid var(--#{$prefix}border-color);
  display: inline-flex;
  align-items: center;
  height: var(--#{$prefix}tag-height);
  border-radius: var(--#{$prefix}border-radius);
  padding: 0 0.5rem;
  background: var(--#{$prefix}bg-surface);
  box-shadow: var(--#{$prefix}box-shadow-input);
  gap: .5rem;

  .btn-close {
    margin-right: -0.25rem;
    margin-left: -0.125rem;
    padding: 0;
    width: 1rem;
    height: 1rem;
    font-size: .5rem;
  }
}

.tag-badge {
  --#{$prefix}badge-font-size: #{$h6-font-size};
  --#{$prefix}badge-padding-x: .25rem;
  --#{$prefix}badge-padding-y: .125rem;
  margin-right: -.25rem;
}

.tag-avatar,
.tag-flag,
.tag-payment,
.tag-icon,
.tag-check {
  margin-left: -.25rem;
}

.tag-icon {
  color: var(--#{$prefix}secondary);
  margin-right: -0.125rem;
  width: 1rem;
  height: 1rem;
}

.tag-check {
  width: 1rem;
  height: 1rem;
  background-size: 1rem;
}


//
// Tags list
//
.tags-list {
  @include elements-list;
}
