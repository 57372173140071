.fslightbox-container {
  font-family: inherit !important;
  background: rgba($modal-backdrop-bg, $modal-backdrop-opacity) !important;
  backdrop-filter: blur($modal-backdrop-blur) !important;
}

.fslightbox-slide-number-container {
  color: inherit !important;
}

.fslightbox-slash {
  background: currentColor !important;
}