@import "jsvectormap/dist/css/jsvectormap";

.jvm-tooltip {
  background: $dark;
  font-family: inherit;
  font-size: $h5-font-size;
  box-shadow: $card-box-shadow;
}

.jvm-series-container .jvm-legend  .jvm-legend-title {
  border-bottom: 0;
  font-weight: var(--#{$prefix}font-weight-medium);
}

.jvm-series-container .jvm-legend {
  background-color: var(--#{$prefix}card-bg, var(--#{$prefix}bg-surface));
  border: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color);
  min-width: 8rem;

  .jvm-legend-inner .jvm-legend-tick {
    display: flex;
    align-items: center;

    .jvm-legend-tick-sample {
      width: .75rem;
      height: .75rem;
    }
  }
}

.jvm-zoom-btn {
  border: var(--#{$prefix}border-width) var(--#{$prefix}border-style) var(--#{$prefix}border-color);
  background: var(--#{$prefix}card-bg, var(--#{$prefix}bg-surface));
  color: var(--#{$prefix}body-color);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  box-shadow: $card-box-shadow;

  &.jvm-zoomout {
    top: 2.5rem;
  }
}