.apexcharts-tooltip {
  color: $tooltip-color !important;
  background: $tooltip-bg !important;
  font-size: $tooltip-font-size !important;
  padding: .25rem !important;
  box-shadow: none !important;
}

.apexcharts-tooltip-title {
  background: transparent !important;
  border: 0 !important;
  margin: 0 !important;
  font-weight: var(--#{$prefix}font-weight-bold);
  padding: .25rem .5rem !important;
}

.apexcharts-tooltip-y-group {
  padding: 2px 0 !important;
}

.apexcharts-tooltip-series-group {
  padding: 0 .5rem 0 !important;
}

.apexcharts-tooltip-marker {
  width: 10px !important;
  height: 10px !important;
}

.apexcharts-text {
  fill: var(--#{$prefix}body-color) !important;
}

.apexcharts-gridline {
  stroke: var(--#{$prefix}border-color) !important;
}

.apexcharts-legend-text {
  color: inherit !important;
}

.apexcharts-radialbar-area {
  stroke: var(--#{$prefix}border-color-dark) !important;
}