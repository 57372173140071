//
// Icon component
//
.icon {
  --#{$prefix}icon-size: #{$font-size-base * $line-height-base};
  width: var(--#{$prefix}icon-size);
  height: var(--#{$prefix}icon-size);
  font-size: var(--#{$prefix}icon-size);
  vertical-align: bottom;

  @if $icon-stroke-width {
    stroke-width: $icon-stroke-width;
  }

  &:hover {
    text-decoration: none;
  }
}

//
// Inline icon
//
.icon-inline {
  --#{$prefix}icon-size: 1rem;
  vertical-align: -.2rem;
}

//
// Filled icon
//
.icon-filled {
  fill: currentColor;
}

//
// Icon size
//
.icon-sm {
  --#{$prefix}icon-size: 1rem;
  stroke-width: 1;
}

.icon-md {
  --#{$prefix}icon-size: 2.5rem;
  stroke-width: 1;
}

.icon-lg {
  --#{$prefix}icon-size: 3.5rem;
  stroke-width: 1;
}

//
// Icons animation
//
.icon-pulse {
  transition: all .15s ease 0s;
  animation: pulse 2s ease infinite;
  animation-fill-mode: both
}

.icon-tada {
  transition: all .15s ease 0s;
  animation: tada 3s ease infinite;
  animation-fill-mode: both
}

.icon-rotate {
  transition: all .15s ease 0s;
  animation: rotate-360 3s linear infinite;
  animation-fill-mode: both
}