.chat {
}

.chat-bubbles {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chat-bubble {
  background: var(--#{$prefix}bg-surface-secondary);
  border-radius: var(--#{$prefix}border-radius-lg);
  padding: 1rem;
  position: relative;
}

.chat-bubble-me {
  background-color: var(--#{$prefix}primary-lt);
  box-shadow: none;
}

.chat-bubble-title {
  margin-bottom: 0.25rem;
}

.chat-bubble-author {
  font-weight: 600;
}

.chat-bubble-date {
  color: var(--#{$prefix}secondary);
}

.chat-bubble-body {
  > *:last-child {
    margin-bottom: 0;
  }
}
